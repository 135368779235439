<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/img/header.jpg')"
      />
      <div class="content-center">
        <div class="container">
          <TypingTitle :titles="['The People Behind Our Success', 'The Minds Behind the Mission']" />
        </div>
      </div>
    </div>

    <div class="section-port section-team text-center">
      <div class="container">
        <h2 class="title-port">
          Here is our team
        </h2>
        <div class="team">
          <div class="row">
            <!--Black card Rikki-->
            <div class="col-md-4">
              <card
                type="profile"
                color="black"
              >
                <img
                  slot="avatar"
                  class="img img-raised"
                  src="img/team/team-2.jpg"
                >
                <div>
                  <h4 class="card-title">
                    Rikki Mumba
                  </h4>
                  <p class="category text-primary">
                    Managing Consultant
                  </p>
                  <p class="card-description">
                    Environmental Management & EIA oversight<br>
                    M.Sc. Env. (Loughborough, UK)<br>
                    B.Sc, (UNZA, ZAMBIA)<br>
                  </p>
                  <!--<a
                      href="#pablo"
                      class="btn btn-icon btn-twitter btn-round"
                  ><i
                      class="fab fa-twitter"
                  /></a>
                  <a
                      href="#pablo"
                      class="btn btn-icon btn-facebook btn-round"
                  ><i
                      class="fab fa-facebook-square"
                  /></a>
                  <a
                      href="#pablo"
                      class="btn btn-icon btn-google btn-round"
                  ><i class="fab fa-google" /></a>-->
                </div>
              </card>
            </div>
            <!--Joshua Chipasha-->
            <div class="col-md-4">
              <card
                type="profile"
                color="black"
              >
                <img
                  slot="avatar"
                  class="img img-raised"
                  src="img/team/team-7.png"
                >
                <div>
                  <h4 class="card-title">
                    Joshua Chipasha
                  </h4>
                  <p class="category text-primary">
                    Consultant
                  </p>

                  <p class="card-description">
                    Technical Specialist, Environmental Management<br>
                    B. Eng. (UNZA, ZAMBIA)<br>
                  </p>
                </div>
              </card>
            </div>

            <!--Khumbo Moyo-->
            <div class="col-md-4">
              <card
                type="profile"
                color="black"
              >
                <img
                  slot="avatar"
                  class="img img-raised"
                  src="img/team/Khumbo.png"
                >
                <div>
                  <h4 class="card-title">
                    Khumbo Moyo
                  </h4>
                  <p class="category text-primary">
                    Associate Consultant
                  </p>
                  <p class="card-description">
                    BSc. Degree in Natural Resource
                    Management. <br>
                    University of Malawi<br>
                  </p>
                  <!--<a
                      href="#pablo"
                      class="btn btn-icon btn-twitter btn-round"
                  ><i
                      class="fab fa-twitter"
                  /></a>
                  <a
                      href="#pablo"
                      class="btn btn-icon btn-facebook btn-round"
                  ><i
                      class="fab fa-facebook-square"
                  /></a>
                  <a
                      href="#pablo"
                      class="btn btn-icon btn-google btn-round"
                  ><i class="fab fa-google" /></a>-->
                </div>
              </card>
            </div>
            <!--Shaba Tadeyo-->
            <div class="col-md-4">
              <card
                type="profile"
                color="black"
              >
                <img
                  slot="avatar"
                  class="img img-raised"
                  src="img/team/Shaba Tadeyo.png"
                >
                <div>
                  <h4 class="card-title">
                    Shaba Tadeyo
                  </h4>
                  <p class="category text-primary">
                    Associate Consultant
                  </p>
                  <p class="card-description">
                    MSc.Forestry and Land Use.<br>
                    University of Oxford, United Kingdom<br>

                    Post-graduate Diploma in Wildlife Management<br>
                    College of African Wildlife, Management,
                    Tanzania <br>
                  </p>
                  <!--<a
                      href="#pablo"
                      class="btn btn-icon btn-twitter btn-round"
                  ><i
                      class="fab fa-twitter"
                  /></a>
                  <a
                      href="#pablo"
                      class="btn btn-icon btn-facebook btn-round"
                  ><i
                      class="fab fa-facebook-square"
                  /></a>
                  <a
                      href="#pablo"
                      class="btn btn-icon btn-google btn-round"
                  ><i class="fab fa-google" /></a>-->
                </div>
              </card>
            </div>

            <!--Lovemore Mwanza-->
            <div class="col-md-4">
              <card
                type="profile"
                color="black"
              >
                <img
                  slot="avatar"
                  class="img img-raised"
                  src="img/team/team-1.png"
                >
                <div>
                  <h4 class="card-title">
                    Lovemore Mwanza
                  </h4>
                  <p class="category text-primary">
                    Consultant
                  </p>
                  <p class="card-description">
                    Monitoring and Evaluation Expert<br>
                    Master of Policy Studies (MPS)<br>
                    Bachelor of Arts (BA)<br>
                    Development Studies<br>
                  </p>
                  <!--<a
                      href="#pablo"
                      class="btn btn-icon btn-twitter btn-round"
                  ><i
                      class="fab fa-twitter"
                  /></a>
                  <a
                      href="#pablo"
                      class="btn btn-icon btn-facebook btn-round"
                  ><i
                      class="fab fa-facebook-square"
                  /></a>
                  <a
                      href="#pablo"
                      class="btn btn-icon btn-google btn-round"
                  ><i class="fab fa-google" /></a>-->
                </div>
              </card>
            </div>

            <!--Rui-->
            <div class="col-md-4">
              <card
                type="profile"
                color="black"
              >
                <img
                  slot="avatar"
                  class="img img-raised"
                  src="img/team/team-3.jpg"
                >
                <div>
                  <h4 class="card-title">
                    Rui
                  </h4>
                  <p class="category text-primary">
                    Consultant
                  </p>

                  <p class="card-description">
                    BA Business Administration, <br>
                    Monash University (RSA)<br>
                  </p>
                </div>
              </card>
            </div>
            <!--Ubaani Zemba-->
            <div class="col-md-4">
              <card
                type="profile"
                color="black"
              >
                <img
                  slot="avatar"
                  class="img img-raised"
                  src="img/team/team-4.jpg"
                >
                <div>
                  <h4 class="card-title">
                    Ubaani Zemba
                  </h4>
                  <p class="category text-primary">
                    Consultant
                  </p>

                  <p class="card-description">
                    Climate change expert<br>
                    Bachelors degree in Environmental Education,<br> (UNZA, ZAMBIA)
                  </p>
                </div>
              </card>
            </div>
            <!--Christopher Chitembo-->
            <div class="col-md-4">
              <card
                type="profile"
                color="black"
              >
                <img
                  slot="avatar"
                  class="img img-raised"
                  src="img/team/team-5.jpg"
                >
                <div>
                  <h4 class="card-title">
                    Christopher Chitembo
                  </h4>
                  <p class="category text-primary">
                    Community liaison officer
                  </p>

                  <p class="card-description">
                    <!--Environmental Management & EIA oversight--><br>
                    <!--M.Sc. Env. (Loughborough, UK)--><br>
                    <!--B.Sc, (UNZA, ZAMBIA)--><br>
                  </p>
                </div>
              </card>
            </div>
            <!--it specialist-->
            <div class="col-md-4">
              <card
                type="profile"
                color="black"
              >
                <img
                  slot="avatar"
                  class="img img-raised"
                  src="img/team/team-6.jpg"
                >
                <div>
                  <h4 class="card-title">
                    Katoma Mwiinga
                  </h4>
                  <p class="category text-primary">
                    I.T Specialist Consultant
                  </p>

                  <p class="card-description">
                    B.B.A in Computer Management & Information Systems,<br>
                    (Rusangu University, ZAMBIA)
                  </p>
                  <a
                    href="https://github.com/yuri-ktm"
                    class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-github" /></a>
                  <a
                    href="https://web.facebook.com/katoma.hillary/"
                    class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook" /></a>
                  <a
                    href="mailto:katoma.hillary@gmail.com"
                    class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-google" /></a>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Card} from '@/components';
import TypingTitle from '@/components/TypingTitle';
export default {
  name: 'Profile',
  bodyClass: 'profile-page',
  components: {
    Card,
    TypingTitle,
  },
};
</script>
<style></style>
